import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardHeader = _resolveComponent("ForwardHeader")!
  const _component_AccountBlock = _resolveComponent("AccountBlock")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_LivePayRegisterForm = _resolveComponent("LivePayRegisterForm")!
  const _component_AccountList = _resolveComponent("AccountList")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_ForwardHeader)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { xl: "3" }, {
            default: _withCtx(() => [
              _createVNode(_component_AccountBlock, { "student-educations": _ctx.studentEducations }, null, 8, ["student-educations"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            xl: "9",
            class: "mt-4 mt-xl-0"
          }, {
            default: _withCtx(() => [
              (_ctx.isLivePayEnabled(_ctx.selectedEducation) && _ctx.isLivePayMandateSetUp !== true && _ctx.isDrivingSchoolEnabled === true)
                ? (_openBlock(), _createBlock(_component_LivePayRegisterForm, { key: 0 }))
                : (_openBlock(), _createBlock(_component_AccountList, { key: 1 }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}